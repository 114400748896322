var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-cast",attrs:{"id":"order-cast"}},[_c('AppContainer',{attrs:{"useBackground":true}},[_c('card-header-body',{ref:"header",attrs:{"node":_vm.headerTitle}}),_c('div',{staticClass:"content-scroll",attrs:{"id":"content-scroll"}},[(_vm.isLoading)?[_c('AppLoading')]:[_c('div',{staticClass:"order__list"},[((_vm.castList.designations || []).length > 0)?[_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.castList.designations),function(cast,index){return [_c('div',{key:'designations_' + index,staticClass:"order__item",class:_vm.isDisabled ||
                    (_vm.getNumberSelected() === _vm.maxCast && !cast.isSelected) ||
                    cast.status !== _vm.STATUS_ORDER.AVAILABLE
                      ? 'disabled'
                      : ''},[_c('b-list-group',{staticStyle:{"max-width":"300px"}},[_c('b-list-group-item',{staticClass:"d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"f-w3 check-box align-items-center",class:_vm.isDisabled ||
                          (_vm.getNumberSelected() === _vm.maxCast &&
                            !cast.isSelected) ||
                          cast.status !== _vm.STATUS_ORDER.AVAILABLE
                            ? 'd-none'
                            : 'd-flex',model:{value:(cast.isSelected),callback:function ($$v) {_vm.$set(cast, "isSelected", $$v)},expression:"cast.isSelected"}}),_c('b-avatar',{staticClass:"mr-3",attrs:{"variant":"info","src":cast.image_url,"button":""},on:{"click":function($event){$event.preventDefault();return _vm.redirectProfile(cast.user_id)}}}),_c('div',{staticClass:"info d-flex"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"status d-flex f-w3",class:cast.status === _vm.STATUS_ORDER.AVAILABLE
                                ? 'online'
                                : ''},[_c('div',{staticClass:"d-flex flex-column justify-content-start",staticStyle:{"margin-right":"15px"}},[_c('p',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" "+_vm._s(cast.female_prego_rank_name)+" ")]),_c('p',{staticClass:"\n                                  text\n                                  mr-auto\n                                  f-w3\n                                  text-nickname\n                                  mb-0\n                                  mr-0\n                                ",staticStyle:{"margin-right":"0px !important","font-size":"14px"},on:{"click":function($event){$event.preventDefault();return _vm.redirectProfile(cast.user_id)}}},[_vm._v(" "+_vm._s(cast.nickname)+" ")])])])]),_c('div',{staticClass:"d-flex flex-column justify-content-start"},[_c('div',{staticClass:"\n                              mark\n                              d-flex\n                              align-items-center\n                              justify-content-center\n                              f-w3\n                            "},[_vm._v(" 個人オファー ")]),_c('p',{staticClass:"text f-w3 mb-0",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(cast.age)+"歳 ")])])]),(cast.is_favorite)?_c('b-icon',{attrs:{"icon":"star-fill"},on:{"click":function($event){return _vm.changeLike(cast)}}}):_c('b-icon',{attrs:{"icon":"star"},on:{"click":function($event){return _vm.changeLike(cast)}}})],1)],1)],1)]})],2)]:_vm._e(),((_vm.castList.applicants || []).length > 0)?[_c('div',{staticClass:"sub-title f-w3 txt-left"},[_vm._v(" ※タイプ、スコア、ポイント等、異なる可能性があり ます。プロフィールを必ず確認し、承認してください。 ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.castList.applicants),function(cast,index){return [_c('div',{key:'applicants_' + index,staticClass:"order__item",class:_vm.isDisabled ||
                    (_vm.getNumberSelected() === _vm.maxCast && !cast.isSelected) ||
                    cast.status !== _vm.STATUS_ORDER.AVAILABLE
                      ? 'disabled'
                      : ''},[_c('b-list-group',{staticStyle:{"max-width":"300px"}},[_c('b-list-group-item',{staticClass:"d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"f-w3 check-box align-items-center",class:_vm.isDisabled ||
                          (_vm.getNumberSelected() === _vm.maxCast &&
                            !cast.isSelected) ||
                          cast.status !== _vm.STATUS_ORDER.AVAILABLE
                            ? 'd-none'
                            : 'd-flex',model:{value:(cast.isSelected),callback:function ($$v) {_vm.$set(cast, "isSelected", $$v)},expression:"cast.isSelected"}}),_c('b-avatar',{staticClass:"mr-3",attrs:{"variant":"info","src":cast.image_url,"button":""},on:{"click":function($event){$event.preventDefault();return _vm.redirectProfile(cast.user_id)}}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"rank f-w3"},[_vm._v(" "+_vm._s(cast.female_prego_rank_name)+" ")]),_c('span',{staticClass:"text mr-auto f-w3 text-nickname",on:{"click":function($event){$event.preventDefault();return _vm.redirectProfile(cast.user_id)}}},[_vm._v(_vm._s(cast.nickname))]),_c('span',{staticClass:"text f-w3"},[_vm._v(_vm._s(cast.age)+"歳")])]),(cast.is_favorite)?_c('b-icon',{attrs:{"icon":"star-fill"},on:{"click":function($event){return _vm.like(cast)}}}):_c('b-icon',{attrs:{"icon":"star"},on:{"click":function($event){return _vm.like(cast)}}})],1)],1)],1)]})],2)]:_vm._e(),(!_vm.checkData)?[_c('p',{staticClass:"f-w6 text-center"},[_vm._v("現在オファーはありません。")])]:_vm._e(),(
              (_vm.castList.applicants || []).length <= 0 &&
                (_vm.castList.designations || []).length <= 0
            )?_c('p',{staticClass:"f-w3"},[_vm._v(" 現在応募中の女性はいません。 ")]):_vm._e()],2),(!_vm.isDisabled)?_c('div',{staticClass:"btn-submit"},[_c('button',{staticClass:"f-w6",attrs:{"disabled":_vm.isDisabled || Number(_vm.getNumberSelected()) !== Number(_vm.maxCast)},on:{"click":function($event){return _vm.matchingOrder()}}},[_vm._v(" 確定("+_vm._s(_vm.getNumberSelected())+"/"+_vm._s(_vm.maxCast)+") ")])]):_vm._e(),_c('ModalLayoutOrderCast',{ref:"modal-maching-cast"},[_c('div',{staticClass:"twinkle"},[_c('img',{attrs:{"src":require("@/assets/image/twinkle_text.svg")}}),_c('p',{staticClass:"f-w3 text-center mb-0 text-note"},[_vm._v("キャンセル料は")]),_c('p',{staticClass:"f-w3 text-center mb-0 text-note"},[_vm._v(" 開催日から2日まで100％ ")]),_c('p',{staticClass:"f-w3 text-center mb-0 text-note"},[_vm._v(" 開催日3日～7日前まで50％ ")]),_c('p',{staticClass:"f-w3 text-center mb-0 text-note"},[_vm._v(" 開催日8日以降特になし ")]),_c('p',{staticClass:"f-w3 text-center mb-0 text-note"},[_vm._v(" になっております。詳しくは"),_c('span',{staticClass:"text-faq",on:{"click":function($event){return _vm.$router.push({ name: 'MenFAQ' })}}},[_vm._v("こちら")]),_vm._v("をご覧ください ")])])])]],2),_c('CardFooter-body',{ref:"footer"}),_c('ModalLayout',{ref:"modal-confirm-maching"},[_c('p',{staticClass:"modal-logout-title f-w3"},[_vm._v(" マッチングが成立します。ポイントが決済されますがよろしいですか。 ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn-logout",on:{"click":function($event){$event.preventDefault();return _vm.orderCast.apply(null, arguments)}}},[_vm._v("はい")]),_c('button',{staticClass:"btn-cancel-logout",on:{"click":function($event){return _vm.$refs['modal-confirm-maching'].closeModal()}}},[_vm._v(" いいえ ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }